import Vue from 'vue'

import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BSpinner,
    BAvatar,
} from "bootstrap-vue";
Vue.component('BFormFile', BFormFile)
Vue.component('BForm', BForm)
Vue.component('BButton', BButton)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BAlert', BAlert)
Vue.component('BCard', BCard)
Vue.component('BCardText', BCardText)
Vue.component('BCardTitle', BCardTitle)
Vue.component('BMedia', BMedia)
Vue.component('BMediaAside', BMediaAside)
Vue.component('BMediaBody', BMediaBody)
Vue.component('BLink', BLink)
Vue.component('BImg', BImg)
Vue.component('BSpinner', BSpinner)
Vue.component('BAvatar', BAvatar)

import AvatarImg from './avatar-img/AvatarImg.vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Alert from "./Alert.vue"

Vue.component('AvatarImg', AvatarImg)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('Alert', Alert)
