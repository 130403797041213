import api from "@/libs/axios";

const dashboard = {
    state: {
        ticketsList: [],
    },
    getters: {
        returnTicketsList: state => state.ticketsList,
    },
    actions: {
        getTicketSupportTeam: ({ commit }, idTicket) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/support/get-ticket-support-team/${idTicket}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getTicketUser: ({ commit }, idTicket) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/support/get-ticket-user/${idTicket}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        createTicket: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(`/support/open-ticket`, data.dataTicket).then(async response => {
                    if (data.attachments.length > 0) {
                        let attachments = new FormData()
                        attachments.append('attachments', data.attachments[0])
                        await api.post(`/support/upload-image/${response.data.ticket._id}`, attachments)
                    }
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        responseTicket: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(`/support/ticket-response`, data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getTicketsList: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let outhersParams = ''
                if (params.search != '' && params.search != null) {
                    outhersParams = outhersParams + `&search=${params.search}`
                }
                if (params.status != '' && params.status != null) {
                    outhersParams = outhersParams + `&status=${params.status}`
                }
                if (params.function != '' && params.function != null) {
                    outhersParams = outhersParams + `&function=${params.function}`
                }
                if (params.sortby == null) {
                    params.sortby = 'created_at'
                }
                if (params.sortdirection == null) {
                    params.sortdirection = 'desc'
                }
                if (params.page == null) {
                    params.page = '1'
                }
                if (params.limit == null) {
                    params.limit = '10'
                }
                await api.get(`/support/list-tickets-user/?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`).then(response => {
                    commit("addTicketList", response.data);
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        getTicketsAll: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let outhersParams = ''
                if (params.search != '' && params.search != null) {
                    outhersParams = outhersParams + `&search=${params.search}`
                }
                if (params.status != '' && params.status != null) {
                    outhersParams = outhersParams + `&status=${params.status}`
                }
                if (params.function != '' && params.function != null) {
                    outhersParams = outhersParams + `&function=${params.function}`
                }
                if (params.sortby == null) {
                    params.sortby = 'created_at'
                }
                if (params.sortdirection == null) {
                    params.sortdirection = 'desc'
                }
                if (params.page == null) {
                    params.page = '1'
                }
                if (params.limit == null) {
                    params.limit = '10'
                }
                await api.get(`/support/list-tickets-all/?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`).then(response => {
                    commit("addTicketList", response.data);
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        sendSecurityCode: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(`/support/email-send`, data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        revealSecretPhrase: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post(`/web3/reveal-secret-phrase`, data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            });
        },
    },
    mutations: {
        addTicketList: (state, list) => {
            state.ticketsList = list
        },
    },
}

export default dashboard;