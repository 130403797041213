import api from "@/libs/axios";

const dashboard = {
  state: {
    ticketsList: [],
  },
  getters: {},
  actions: {
    arbitrationOportunity: ({ commit }, idTicket) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/arbitration/manual-execution`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    arbitrationExecute: ({ commit }, offer) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/arbitration/manual-execution`, offer)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    automatedArbitrage: ({ commit }, isAutomatic) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/arbitration/automated-arbitrage`, { isAutomatic })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    initialAmountWithdrawn: ({ commit }, robotId) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/arbitration/initial-amount-withdrawn`, { robotId })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
  mutations: {},
};

export default dashboard;
