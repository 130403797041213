import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authentication from './authentication';
import administrator from "./administrator"
import dashboard from "./dashboard"
import trading from "./trading"
import userFinancial from "./user-financial"
import affiliates from "./affiliates"
import support from "./support"
import arbitration from "./arbitration"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authentication,
    administrator,
    dashboard,
    trading,
    userFinancial,
    affiliates,
    support,
    arbitration
  },
  strict: process.env.DEV,
})