import api from "@/libs/axios";

const trading = {
  state: {
    licenseManagerCustomerData: [],
  },
  getters: {
    returnLicenseManagerCustomer: (state) => state.licenseManagerCustomerData,
  },
  actions: {
    buyNewLicense: ({ commit }, idLicense) => {
      const data = {
        idLicense: idLicense,
      };
      return new Promise(async (resolve, reject) => {
        await api
          .post("/arbitration/buy-licence", data)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    allocateValueInTrading: ({ commit }, balance) => {
      const data = {
        valueLicence: balance,
      };
      return new Promise(async (resolve, reject) => {
        await api
          .post("/arbitration/buy-licence", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    contributionValueTrading: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/arbitration/value-allocation", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    upgradeLicence: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/arbitration/buy-licence", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    renewalLicence: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/arbitration/renew-license", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    withdrawValueTrading: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/trader/robot-withdraw", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    changeConfigRobot: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/arbitration/config-robot-arbitration", config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    searchConfigRobot: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/arbitration/search-config-robot-arbitration")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    searchConfigDepositsWithdrawls: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/admin/search-config-deposits-withdrawls")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    changeConfigDepositsWithdrawls: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/admin/config-deposits-withdrawls", config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    searchConfigLicenseWithdrawls: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/admin/search-config-license")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    changeConfigLicenseWithdrawls: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/admin/config-license", config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    getListLicenses: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/admin/get-licenses")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },    
    customerListSearch: ({ commit }, params) => {

      return new Promise(async (resolve, reject) => {

        await api.get(`/admin/search-customer?customerSearch=${params.customerSearch}`).then(response => {
          //commit("ADD_PATIENT_LIST", response.data);
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data.message)
        })
      });
    },
    customerListSearchCustomer: ({ commit }, params) => {

      return new Promise(async (resolve, reject) => {

        await api.get(`/sponsorship/search-customer?customerSearch=${params.customerSearch}`).then(response => {
          //commit("ADD_PATIENT_LIST", response.data);
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data.message)
        })
      });
    },
    changeConfigLicenseWithdrawls: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/admin/config-license", config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    fetchLicenseManagerCustomer: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }

        await api
          .get(
            `/sponsorship/license-manager?page=${params.page}&limit=${params.limit}${outhersParams}`
          )
          .then((response) => {
            commit("addLicenseManagerCustomer", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    addLicenseCustomer: ({ commit }, dataForm) => {
      return new Promise(async (resolve, reject) => {

        await api
          .post("/sponsorship/add-license-manager", dataForm)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    addSponsorshipContributionCustomer: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/sponsorship/sponsorship-contribution`, data)
          .then((response) => {
            resolve(response.data.message);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },

    //DEXBOT ################
    getInfoActivePoolsCustomer: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/dashboard/dexbot/info-active-pools`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    getUpdatePools: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/dashboard/dexbot/get-update-pools`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    }
  },
  mutations: {
    addLicenseManagerCustomer: (state, licenseListCustomer) => {
      state.licenseManagerCustomerData = licenseListCustomer;
    },
  },
};

export default trading;
