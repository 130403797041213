import api from "@/libs/axios";

const administrator = {
  state: {
    usersList: [],
    individualUserData: [],
    balanceManagerData: [],
    licenseManagerData: [],
    poolsLiquidityData: [],
    hiloGameStatistics: null,
    jokenPoGameStatistics: null,
  },
  getters: {
    returnUsersList: (state) => state.usersList,
    returnIndividualUser: (state) => state.individualUserData,
    returnBalanceManager: (state) => state.balanceManagerData,
    returnPoolsLiquidity: (state) => state.poolsLiquidityData,
    returnHiloGameStatistics: (state) => state.hiloGameStatistics,
    returnJokenPoGameStatistics: (state) => state.jokenPoGameStatistics,
    returnLicenseManager: (state) => state.licenseManagerData,
  },
  actions: {
    usersList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/admin/users-list/?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUsersList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    hiloGameStatistics: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/admin/game/hilo-statistics-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}&startDate=${params.startDate}&endDate=${params.endDate}${outhersParams}`
          )
          .then((response) => {
            commit("addHiloGameStatistics", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    jokenPoGameStatistics: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/admin/game/jokenpo-statistics-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}&startDate=${params.startDate}&endDate=${params.endDate}${outhersParams}`
          )
          .then((response) => {
            commit("addJokenPoGameStatistics", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    changeConfigHilo: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/admin/game/config-hilo", config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    changeConfigJokenPo: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/admin/game/config-jokenpo", config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    addNewUser: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        function removeAccents(str) {
          const with_accent =
            "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

          const no_accent =
            "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";

          let newStr = "";

          for (let i = 0; i < str.length; i++) {
            let replacement = false;
            for (let a = 0; a < with_accent.length; a++) {
              if (str.substr(i, 1) == with_accent.substr(a, 1)) {
                newStr += no_accent.substr(a, 1);
                replacement = true;
                break;
              }
            }
            if (replacement == false) {
              newStr += str.substr(i, 1);
            }
          }
          return newStr;
        }

        function getRandomArbitrary(min, max) {
          return Math.random() * (max - min) + min;
        }

        let fullName = removeAccents(data.fullName);
        fullName = fullName.replace(/[^a-zA-Zs]/g, "").toLowerCase();
        let randomNumber = getRandomArbitrary(1, 99999);
        fullName = fullName.substring(0, 13) + Math.floor(randomNumber);
        data.username = fullName;

        await api
          .post("/admin/user", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    sendEmailAPI: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/admin/sendemail/creation/user/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    disableUserAPI: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/user/${data.id}/edit`, {
            status: data.status,
            reason: data.description,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    fetchUser: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/user/${id}`)
          .then((response) => {
            commit("addIndividualUser", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    fetchBalanceManager: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }

        await api
          .get(
            `/balance-manager?page=${params.page}&limit=${params.limit}${outhersParams}`
          )
          .then((response) => {
            commit("addBalanceManager", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    fetchLicenseManager: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }

        await api
          .get(
            `/admin/license-manager?page=${params.page}&limit=${params.limit}${outhersParams}`
          )
          .then((response) => {
            commit("addLicenseManager", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    addLicenseAdmin: ({ commit }, dataForm) => {
      return new Promise(async (resolve, reject) => {

        await api
          .post("/admin/add-license-manager", dataForm)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    addRemoveBalance: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/balance-manager/add-remove-balance`, data)
          .then((response) => {

            resolve(response.data.message);
          })
          .catch((error) => {

            reject(error.response);
          });
      });
    },
    addExpireLicense: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/admin/license-manager/add-expire-license`, data)
          .then((response) => {

            resolve(response.data.message);
          })
          .catch((error) => {

            reject(error.response);
          });
      });
    },
    addSponsorshipContribution: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/sponsorship-contribution`, data)
          .then((response) => {
            resolve(response.data.message);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    fetchPoolsLiquidity: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }

        await api
          .get(
            `/pools-liquidity?page=${params.page}&limit=${params.limit}${outhersParams}`
          )
          .then((response) => {
            commit("addPoolsLiquidity", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    addNewPoolLiquidity: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/pools-liquidity/add-new`, data)
          .then((response) => {
            resolve(response.data.message);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    changePoolLiquidity: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/pools-liquidity/change`, data)
          .then((response) => {
            resolve(response.data.message);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    editUser: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        if (data.avatar) {
          if (data.avatar !== "remove") {
            let avatar = new FormData();
            avatar.append("avatar", data.avatar);
            await api.post(`/admin/user/${data.id}/upload-image`, avatar);
            resolve("");
          } else {
            await api.delete(`/admin/user/${data.id}/avatar`);
            resolve("");
          }
        }

        if (data.userInfo) {
          await api
            .patch(`/admin/user/${data.id}/edit`, data.userInfo)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response.data);
            });
        } else {
          reject("");
        }
      });
    },
    addUserRole: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        if (data.method == "add") {
          await api
            .patch(`/roles/${data.id_user}/add-role`, { roles: [data.id_role] })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response.data.errors);
            });
        } else {
          await api
            .post(`/roles/${data.id_user}/remove-role`, {
              roles: ["62a938f7ead20c947e7aa61c"],
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response.data.errors);
            });
        }
      });
    },
    fetchRolesList: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/admin/roles")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    dashboardAdmin: ({ commit }, selectedLeg) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/general-view`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    //DEXBOT ################
    getInfoActivePools: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/dexbot/info-active-pools`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    getPoolEvolutionGraph: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/dexbot/get-pool-evolution-graph`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    getWalletBalance: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/dexbot/info-wallet-balance`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    getParticipationLicensesPool: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/dexbot/participation-licenses-pool`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    removePool: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/dexbot/remove-pool`, { poolId: id })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    updatingPools: ({ commit }, status) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/dexbot/update-pool`, { updatingPools: status })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    searchConfigBotPool: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/admin/pool/bot-config")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      })
        ;
    },
    changeConfigBotPool: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/admin/pool/bot-config", config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
  mutations: {
    addHiloGameStatistics: (state, HiloGameStatistics) => {
      state.hiloGameStatistics = HiloGameStatistics;
    },
    addJokenPoGameStatistics: (state, JokenPoGameStatistics) => {
      state.jokenPoGameStatistics = JokenPoGameStatistics;
    },
    addUsersList: (state, users) => {
      state.usersList = users;
    },
    addIndividualUser: (state, user) => {
      state.individualUserData = user;
    },
    addBalanceManager: (state, balanceList) => {
      state.balanceManagerData = balanceList;
    },
    addPoolsLiquidity: (state, poolsLiquidity) => {
      state.poolsLiquidityData = poolsLiquidity;
    },
    addLicenseManager: (state, licenseList) => {
      state.licenseManagerData = licenseList;
    },
  },
};

export default administrator;
