<template>
  <!-- media -->
  <b-media no-body>
    <b-media-aside style="display: flex; flex-direction: column; align-items: center;">
      <!-- avatar -->
      <b-avatar :size="size" :src="urlImage ? urlImage : avatar" :text="avatarText(textNoImg)" :rounded="rounded" />
      <!--/ avatar -->

      <b-media-body class="mt-75 ml-75" style="display: flex; flex-direction: column; align-items: center;">
        <!-- upload button -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" size="md" class="mb-75 mr-75 mt-auto "
          @click="$refs.refInputEl.$el.click()">
          {{ $t("Alterar foto") }}
        </b-button>
        <b-form-file ref="refInputEl" accept=".jpg, .png, .gif" :hidden="true" plain @change="onFileChange" />
        <!--/ upload button -->

        <b-card-text :class="[profileFile && 'text-warning', 'text-center']" style="max-width: 14rem; font-size: smaller;">
          {{ $t('JPG, GIF ou PNG permitidos. Tamanho máximo de 4MB') }}
        </b-card-text>
      </b-media-body>
    </b-media-aside>


  </b-media>
  <!--/ media -->
</template>

<script>
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },

  data() {
    return {
      urlImage: null,
      profileFile: null,
    };
  },
  props: {
    avatar: String,
    textNoImg: String,
    rounded: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "80"
    }
  },

  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImage = URL.createObjectURL(file);
      this.profileFile = file;
      this.$emit("avatarFile", file);
    },
  },

  setup() {
    avatarText;

    return {
      avatarText,
    };
  },
};
</script>