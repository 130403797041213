import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueMask from 'v-mask'

import '@/libs/acl'

// Global Components
import './views/global-components/global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Animate CSS
import 'animate.css';

// SweetAlertIcons
import SweetAlertIcons from 'vue-sweetalert-icons';
Vue.use(SweetAlertIcons);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueMask)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import Numeral from "numeral"
Vue.mixin({
  methods: {
    moneyFormat(value) {
      var number = value;
      var myNumeral = Numeral(number);
      var currencyString = myNumeral.format('0,0.00');
      return currencyString + ' USDT'
    },
    moneyFormat4Dec(value) {
      var number = value;
      var myNumeral = Numeral(number);
      var currencyString = myNumeral.format('0,0.0000');
      return '$' + currencyString
    },
    moneyFormatFiat(value) {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },  
  },
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
