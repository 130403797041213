import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import Cookie from "js-cookie";
import { canNavigate } from "@/libs/acl/routeProtection";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // *===============================================---*
    // *--------- PUBLIC ---------------------------------*
    // *===============================================---*
    {
      path: "/login/:locale?",
      name: "login",
      component: () => import("@/views/authentication/Login.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/ref/:upline/:locale?",
      name: "register",
      component: () => import("@/views/authentication/Register.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/authentication/ForgotPassword.vue"),
      meta: {
        layout: "full",
        resource: "Public",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/authentication/ResetPassword.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/new-password/:token/:email",
      name: "new-password",
      component: () => import("@/views/authentication/NewPassword.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/oauth/callback/token/:token",
      name: "callback-oauth",
      component: () => import("@/views/authentication/CallbackOAuth.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/verify-email/:id/:token",
      name: "verify-email",
      component: () => import("@/views/authentication/VerifyEmail.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/not-authorized",
      name: "not-authorized",
      // ! Update import path
      component: () => import("@/views/error/NotAuthorized.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },

    // *===============================================---*
    // *--------- CUSTOMER -------------------------------*
    // *===============================================---*
    {
      path: "/",
      name: "dashboard-my-results",
      component: () => import("@/views/dashboard/my-results/MyResults.vue"),
      meta: {
        // pageTitle: "Dashboard",
        // breadcrumb: [
        //   {
        //     text: "Arbitrage robot",
        //     active: true,
        //   },
        //   {
        //     text: "Dashboard",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/licenses",
      name: "licenses",
      component: () => import("@/views/dashboard/my-results/Licenses.vue"),
      meta: {
        // pageTitle: "Dashboard",
        // breadcrumb: [
        //   {
        //     text: "Arbitrage robot",
        //     active: true,
        //   },
        //   {
        //     text: "Dashboard",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/dashboard-analytics",
      name: "dashboard-analytics",
      component: () => import("@/views/dashboard/trading-robot/Analytics.vue"),
      meta: {
        pageTitle: "Distribuição de lucros",
        breadcrumb: [
          {
            text: "Distribuição de lucros",
            active: true,
          },
        ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/token-analytics",
      name: "token-analytics",
      component: () => import("@/views/dashboard/tokenYESC.vue"),
      meta: {
        // pageTitle: "Token YESC",
        // breadcrumb: [
        //   {
        //     text: "Token YESC",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/manual-arbitration",
      name: "manual-arbitration",
      component: () =>
        import("@/views/ManualArbitration/ManualArbitration.vue"),
      meta: {
        // pageTitle: "Arbitragem",
        // breadcrumb: [
        //   {
        //     text: "Arbitragem",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    //courses
    {
      path: "/courses",
      name: "courses",
      component: () =>
        import("@/views/courses/Courses.vue"),
      meta: {
        // pageTitle: "Arbitragem",
        // breadcrumb: [
        //   {
        //     text: "Arbitragem",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/affiliate-network/binary-tree",
      name: "affiliate-network-binary-tree",
      component: () => import("@/views/affiliate-network/BinaryTree.vue"),
      meta: {
        // pageTitle: "Binary tree",
        // breadcrumb: [
        //   {
        //     text: "Affiliate network",
        //     to: "/affiliate-network/dashboard",
        //   },
        //   {
        //     text: "Binary tree",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/affiliate-network/direct-references",
      name: "affiliate-network-direct-references",
      component: () => import("@/views/affiliate-network/ReferenceList.vue"),
      meta: {
        // pageTitle: "Direct References",
        // breadcrumb: [
        //   {
        //     text: "Affiliate network",
        //     to: "/affiliate-network/dashboard",
        //   },
        //   {
        //     text: "Direct References",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/affiliate-network/dashboard",
      name: "affiliate-network-dashboard",
      component: () =>
        import("@/views/affiliate-network/dashboard-affiliates/Dashboard.vue"),
      meta: {
        // pageTitle: "Affiliate dashboard",
        // breadcrumb: [
        //   {
        //     text: "Affiliate network",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-deposits",
      name: "wallet-my-deposits",
      component: () => import("@/views/wallet/DepositsList.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },

    {
      path: "/wallet/my-deposits-nav",
      name: "wallet-my-deposits-nav",
      component: () => import("@/views/wallet/DepositsList.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/deposit-model",
      name: "deposit-model",
      beforeEnter() {
        window.open("https://wa.me/message/6QSVCAM7BNWCM1", "_blank");
      },
      meta: {
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-deposits-pix",
      name: "wallet-my-deposits-pix",
      component: () => import("@/views/wallet/DepositsListPix.vue"),
      meta: {
        // pageTitle: "Deposits Pix",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Pix",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-withdrawals",
      name: "wallet-my-withdrawals",
      component: () => import("@/views/wallet/WithdrawalsList.vue"),
      meta: {
        // pageTitle: "Withdrawals",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Withdrawals",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-withdrawals-pix",
      name: "wallet-my-withdrawals-pix",
      component: () => import("@/views/wallet/WithdrawalsListPIX.vue"),
      meta: {
        // pageTitle: "Withdrawals PIX",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Withdrawals PIX",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/transfers",
      name: "wallet-transfers",
      component: () => import("@/views/wallet/TransfersList.vue"),
      meta: {
        // pageTitle: "Transfers",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Transfers",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/account-setting/:tab",
      name: "account-setting",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        // pageTitle: "Account Settings",
        // breadcrumb: [
        //   {
        //     text: "Account Settings",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support",
      name: "support",
      component: () => import("@/views/support/Support.vue"),
      meta: {
        // pageTitle: "Support",
        // breadcrumb: [
        //   {
        //     text: "Support",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support-whatsapp",
      name: "support-whatsapp",
      beforeEnter() {
        window.open("https://wa.me/message/6QSVCAM7BNWCM1", "_blank");
      },
      meta: {
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support/new-ticket",
      name: "support-new-ticket",
      component: () => import("@/views/support/NewTicket.vue"),
      meta: {
        // pageTitle: "Support",
        // breadcrumb: [
        //   {
        //     text: "Support",
        //     to: "/support",
        //   },
        //   {
        //     text: "New ticket",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support/ticket/:idTicket",
      name: "support-ticket",
      component: () => import("@/views/support/TicketResponse.vue"),
      meta: {
        // pageTitle: "Support",
        // breadcrumb: [
        //   {
        //     text: "Support",
        //     to: "/support",
        //   },
        //   {
        //     text: "Ticket",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },

    // *===============================================---*
    // *--------- DASHBOARD ------------------------------*
    // ================================================---*

    {
      path: "/trading-bot",
      name: "/dashboard/analytics/trading-bot",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        // pageTitle: "Trading Bot",
        // breadcrumb: [
        //   {
        //     text: "dashboards",
        //     active: true,
        //   },
        //   {
        //     text: "trading-bot",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/pools-liquidity-list",
      name: "pools-liquidity-customer",
      component: () => import("@/views/pools/PoolsLiquidityCustomer.vue"),
      meta: {
        // pageTitle: "Pools Liquidity",
        // breadcrumb: [
        //   {
        //     text: "Pools",
        //     active: true,
        //   },
        //   {
        //     text: "Pools Liquidity",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/sponsorship",
      name: "sponsorship",
      component: () => import("@/views/Sponsorship.vue"),
      meta: {
        // pageTitle: "Sponsorship",
        // breadcrumb: [
        //   {
        //     text: "Sponsorship",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },

    // *===============================================---*
    // *--------- ADMINISTRATOR ---- ---------------------*
    // *===============================================---*
    {
      path: "/administrator/dashboard",
      name: "administrator-dashboard",
      component: () => import("@/views/administrator/Dashboard.vue"),
      meta: {
        pageTitle: "General view",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "General view",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/administrator/robot-config",
      name: "administrator-robot-config",
      component: () => import("@/views/administrator/RobotConfig.vue"),
      meta: {
        pageTitle: "Robot config",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Robot config",
            active: true,
          },
        ],
        action: "read",
        resource: "robot-config",
      },
    },
    {
      path: "/administrator/deposits-withdrawal-config",
      name: "administrator-deposits-withdrawal-config",
      component: () => import("@/views/administrator/DepositsWithdrawalConfig.vue"),
      meta: {
        pageTitle: "Deposits Withdrawal config",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Deposits Withdrawal config",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/administrator/license-config",
      name: "administrator-license-config",
      component: () => import("@/views/administrator/LicenseConfig.vue"),
      meta: {
        pageTitle: "License config",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "License config",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/administrator/set-permissions",
      name: "administrator-set-permissions",
      component: () => import("@/views/administrator/SetPermissions.vue"),
      meta: {
        pageTitle: "Set permissions",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Set permissions",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/deposits-pix-approve-reject",
      name: "deposits-pix-approve-reject",
      component: () =>
        import("@/views/administrator/DepositsListPixApproveReject.vue"),
      meta: {
        pageTitle: "Approve/reject PIX deposits",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Approve/reject PIX deposits",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/divergence-list",
      name: "divergence-list",
      component: () => import("@/views/administrator/DivergenceList.vue"),
      meta: {
        pageTitle: "Usuários com divergência financeira",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Usuários com divergência financeira",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/crypto-withdrawal-list",
      name: "crypto-withdrawal-list",
      component: () => import("@/views/administrator/crypto/CryptoWithdrawalList.vue"),
      meta: {
        pageTitle: "Crypto Withdrawal List",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Crypto Withdrawal List",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/pix-withdrawal-list",
      name: "pix-withdrawal-list",
      component: () => import("@/views/administrator/PIXWithdrawalList.vue"),
      meta: {
        pageTitle: "PIX Withdrawal List",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "PIX Withdrawal List",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/crypto-deposits-list",
      name: "crypto-deposits-list",
      component: () => import("@/views/administrator/crypto/CryptoDepositsList.vue"),
      meta: {
        pageTitle: "Crypto Deposits List",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Crypto Deposits List",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/transfers-reports",
      name: "transfers-reports",
      component: () => import("@/views/administrator/TransfersReports.vue"),
      meta: {
        pageTitle: "Transfers Reports",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Transfers Reports",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/add-remove-balance",
      name: "add-remove-balance",
      component: () => import("@/views/administrator/AddRemoveBalance.vue"),
      meta: {
        pageTitle: "Balance manager",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Balance manager",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/add-remove-license",
      name: "add-remove-license",
      component: () => import("@/views/administrator/AddRemoveLicense.vue"),
      meta: {
        pageTitle: "License manager",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "License manager",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/pools-liquidity",
      name: "pools-liquidity",
      component: () => import("@/views/administrator/PoolsLiquidity.vue"),
      meta: {
        pageTitle: "Pools Liquidity",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Pools Liquidity",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/administrator/dashboard-dexbot",
      name: "administrator-dashboard-dexbot",
      component: () =>
        import("@/views/administrator/dexbot/DashboardPools.vue"),
      meta: {
        pageTitle: "Dashboard DexBot",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Dashboard Dex",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/administrator/bot_pool_configs",
      name: "administrator-bot_pool_configs",
      component: () => import("@/views/administrator/dexbot/BotPoolConfigs.vue"),
      meta: {
        pageTitle: "Bot Pool Configs",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Bot Pool Configs",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },

    // *===============================================---*
    // *--------- CUSTOMER SERVICE -----------------------*
    // *===============================================---*

    {
      path: "/customer-service/tickets-list",
      name: "customer-service-tickets-list",
      component: () => import("@/views/customer-service/TicketsList.vue"),
      meta: {
        pageTitle: "Tickets list",
        breadcrumb: [
          {
            text: "Customer service",
            active: true,
          },
          {
            text: "Tickets list",
            active: true,
          },
        ],
        action: "read",
        resource: "support_general",
      },
    },
    {
      path: "/customer-service/ticket-response/:idTicket",
      name: "customer-service-ticket-response",
      component: () => import("@/views/customer-service/TicketResponse.vue"),
      meta: {
        pageTitle: "Reply to support ticket",
        breadcrumb: [
          {
            text: "Customer service",
            to: "/customer-service/tickets-list",
          },
          {
            text: "Reply to support ticket",
            active: true,
          },
        ],
        action: "read",
        resource: "support_general",
      },
    },
    {
      path: "/administrator/users/list",
      name: "administrator-users-list",
      component: () =>
        import("@/views/administrator/user/users-list/UsersList.vue"),
      meta: {
        pageTitle: "Users list",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Users list",
            active: true,
          },
        ],
        action: "read",
        resource: "support_general",
      },
    },
    {
      path: "/administrator/users/view/:id",
      name: "administrator-users-view",
      component: () =>
        import("@/views/administrator/user/users-view/UsersView.vue"),
      meta: {
        pageTitle: "Users view",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Users list",
            to: "/administrator/users/list",
          },
          {
            text: "Users view",
            active: true,
          },
        ],
        action: "read",
        resource: "support_general",
      },
    },
    {
      path: "/administrator/users/edit/:id",
      name: "administrator-users-edit",
      component: () =>
        import("@/views/administrator/user/users-edit/UsersEdit.vue"),
      meta: {
        pageTitle: "Users edit",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Users list",
            to: "/administrator/users/list",
          },
          {
            text: "Users view",
            to: "/administrator/users/view/:id",
          },
          {
            text: "Users edit",
            active: true,
          },
        ],
        action: "read",
        resource: "support_general",
      },
    },
    {
      path: "/administrator/game/hilo",
      name: "administrator-game-hilo",
      component: () =>
        import("@/views/administrator/game/hilo/HiloDashboard.vue"),
      meta: {
        pageTitle: "DashBoard HiLo",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Games",
            active: true,
          },
          {
            text: "DashBoard HiLo",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/administrator/game/jokenpo",
      name: "administrator-game-jokenpo",
      component: () =>
        import("@/views/administrator/game/jokenpo/JokenPoDashboard.vue"),
      meta: {
        pageTitle: "DashBoard JokenPo",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Games",
            active: true,
          },
          {
            text: "DashBoard JokenPo",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },
    {
      path: "/administrator/scraping/jokenpo",
      name: "administrator-scraping-targor-dex",
      component: () =>
        import("@/views/administrator/game/jokenpo/JokenPoDashboard.vue"),
      meta: {
        pageTitle: "DashBoard JokenPo",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Games",
            active: true,
          },
          {
            text: "DashBoard JokenPo",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_views",
      },
    },

    // *===============================================---*
    // *--------- CUSTOMER SERVICE -----------------------*
    // *===============================================---*
    {
      path: "/customer-service/tickets-list",
      name: "customer-service-tickets-list",
      component: () => import("@/views/customer-service/TicketsList.vue"),
      meta: {
        pageTitle: "Tickets list",
        breadcrumb: [
          {
            text: "Customer service",
            active: true,
          },
          {
            text: "Tickets list",
            active: true,
          },
        ],
        action: "read",
        resource: "customer-service_list-tickets",
      },
    },
    {
      path: "/customer-service/ticket-response/:idTicket",
      name: "customer-service-ticket-response",
      component: () => import("@/views/customer-service/TicketResponse.vue"),
      meta: {
        pageTitle: "Reply to support ticket",
        breadcrumb: [
          {
            text: "Customer service",
            to: "/customer-service/tickets-list",
          },
          {
            text: "Reply to support ticket",
            active: true,
          },
        ],
        action: "read",
        resource: "customer-service_response-ticket",
      },
    },
    // *===============================================---*
    // *--------- GAME ALL -----------------------*
    // *===============================================---*
    {
      path: "/game/hilo",
      name: "game_hilo",
      component: () => import("@/views/game/hilo/GameHiLo.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/game/rock_paper_scissors",
      name: "rock_paper_scissors",
      component: () => import("@/views/game/rock_paper_scissors/RockPaperScissors.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/game/roulette",
      name: "roulette",
      component: () => import("@/views/game/roulette/Roulette.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/game/card_finding",
      name: "card_finding",
      component: () => import("@/views/game/card_finding/CardFinding.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/game/dice_rolling",
      name: "dice_rolling",
      component: () => import("@/views/game/dice_rolling/DiceRolling.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/game/number_slot",
      name: "number_slot",
      component: () => import("@/views/game/number_slot/NumberSlot.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },   

    // *===============================================---*
    // *--------- ERROR ---- ----------------------------*
    // *===============================================---*
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// Router Before Each hook for route protection
router.beforeEach(async (to, _, next) => {
  let isLoggedIn = Cookie.get("yespagToken");

  const userData = store.state.authentication.user;

  if (isLoggedIn && userData.length == 0) {
    await store.dispatch("recoverUserData").catch((err) => {
      Cookie.remove("yespagToken");
      isLoggedIn = false;
    });
  }

  setTimeout(() => {
    if (!canNavigate(to, store.state.authentication.user)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: "login" });

      // If logged in => not authorized
      return next({ name: "not-authorized" });
    }
  }, 100);

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/");
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
